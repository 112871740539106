import { Fragment, useEffect, useState } from "react";

import {
  AreaChartOutlined,
  UserOutlined,
  CheckCircleFilled,
  DeleteOutlined,
  DollarTwoTone,
  EditOutlined,
  FilePdfTwoTone,
  FileTextTwoTone,
  FlagTwoTone,
  MessageTwoTone,
  MoreOutlined,
  PieChartFilled,
  SelectOutlined,
  ShoppingTwoTone,
  StarTwoTone,
  UserSwitchOutlined
} from "@ant-design/icons";
import { GridContent, PageContainer } from "@ant-design/pro-layout";
import {
  Alert,
  Button,
  Card,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Statistic,
  Switch,
  Tabs,
  Tag,
  Row
} from "antd";
import {
  ProFormRadio,
} from "@ant-design/pro-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageUpload from "../../components/Common/ImageUpload";
import { getUser } from "../../helpers";
import { paymentRemaining, paymentStatus, checkingTAX,zohoAccountCheck } from "../../helpers/order";
import marbleApi from "../../services/http/marbleApi";
import Emails from "./components/Emails";
import Invoices from "./components/Invoices";
import OrderProgress from "./components/OrderProgress";
import Payments from "./components/Payments";
import Products from "./components/ProductsTab";
import QuotePDF from "./components/QuotePDF";
import Reports from "./components/Reports";
import Owner from "./components/Owner";
import SalePDF from "./components/SalePDF";
import SpecialProducts from "./components/SpecialProducts";
import styles from "./style.less";
import LinkClient from "./new/LinkClient";

const { TabPane } = Tabs;

const Order = () => {
  const [tabStatus, seTabStatus] = useState({
    operationKey: "tab1",
    tabActiveKey: "tab1",
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [closingReason, setClosingReason] = useState(null);
  const [reasonModalVisible, setReasonModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const [url, setUrl] = useState(null);
  const [client, setClient] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [zohoAccount, setZohoAccount] = useState(null);

  const signatureCallback = (action, data) => {
    if (action === "upload") setUrl(data.url);
    if (action === "delete") setUrl(null);
  };

  const { id } = params;
  const extraActions = <Button onClick={()=>setReasonModalVisible(true)}>closed lost</Button>;

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        icon={<AreaChartOutlined />}
        onClick={() => setModalVisible1(true)}
      >
        Reports
      </Menu.Item>
      <Menu.Item
        key="2"
        disabled={getUser().role !== "ADMIN" && getUser().id !== order?.User?.id}
        icon={<UserSwitchOutlined />}
        onClick={() => setModalVisible2(true)}
      >
        Change owner
      </Menu.Item>
      <Menu.Item
        key="3"
        disabled={getUser().role !== "ADMIN" && getUser().id !== order?.User?.id}
        icon={<UserSwitchOutlined />}
        onClick={() => setPreviewVisible(true)}
      >
        Change contact
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    async function getOrder() {
      setLoading(true);
      try {
        const response = await marbleApi.http.get(
          marbleApi.urls.order?.get(id)
        );
        setLoading(false);
        if (!response) {
          message.warning("Order not found");
          navigate("/orders");
        }
        if (response) {
          setOrder(response);
        }
      } catch (error) {
        setLoading(false);
        message.error(error?.message);
      }
    }
    getOrder();
  }, [id]);

  async function confirm(e) {
    await handleDelete();
  }

  function cancel(e) {
    console.log(e);
  }

  async function getOrder() {
    setLoading(true);
    try {
      const response = await marbleApi.http.get(marbleApi.urls.order?.get(id));
      setLoading(false);
      if (!response) {
        message.warning("Order not found");
        navigate("/orders");
      }
      if (response) {
        setOrder(response);
      }
    } catch (error) {
      setLoading(false);
      message.error(error?.message);
    }
  }

  const handleUpdate = async (fields, id) => {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id && order?.User?.id) {
      message.error("only order owner can make changes");
    }
    else {
    const hide = message.loading("updating order");
    if (url) {
      fields.outOfstatePDF = url;
    }
    try {
      await marbleApi.http.post(marbleApi.urls.order.update(id), {
        data: fields,
        myorder: order,
        reason: closingReason
      });
      hide();
      message.success("Order updated successfully");
      setModalVisible(false);
      setPreviewVisible(false)
      await getOrder();
      return true;
    } catch (error) {
      hide();
      message.error(error.message);
      return false;
    }
    }
    
  };

  const handleDelete = async () => {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id && order?.User?.id) {
      message.error("only order owner can make changes");
    }
    else {
      const hide = message.loading("deleting order");
      try {
        const response = await marbleApi.http.delete(
          marbleApi.urls.order.delete(id)
        );
        hide();
        message.success(response.message);
        if (response.status === "success") navigate("/orders");
        return true;
      } catch (error) {
        hide();
        message.error(error.message);
        return false;
      }
    }
  };

  async function taxUpdate(value) {
    if (value===false) {
     await handleUpdate({tax:0,totalPrice:order.subTotal}, order.id)
    }
    else {
      const tax = checkingTAX(getUser(), client)
      const calTax = (tax/ 100) * order.subTotal;
      const calTotalPrice = calTax + order.subTotal;
      console.log(calTotalPrice)
      await handleUpdate({tax: tax, totalPrice:calTotalPrice}, order.id)
    }
  }

  const action = (
  
    <Fragment>
     {  getUser().role !== "ACCOUNTING" && <Popconfirm
        title="Are you sure to delete this order?"
        onConfirm={confirm}
        onCancel={cancel}
        okText="Yes"
        cancelText="No"
        
      >
        <Button  type="text" danger>
          <DeleteOutlined />
          Delete
        </Button>
      </Popconfirm>}

      <Button type="text" onClick={() => setModalVisible(true)}>
        <EditOutlined /> Edit
      </Button>
      <Dropdown overlay={menu}>
        <Button type="link">
          <MoreOutlined />
        </Button>
      </Dropdown>
    </Fragment>
  );

  const extra = (
    <div className={styles.moreInfo}>
      <Statistic
        title="Order Total "
        value={order?.subTotal?.toFixed(2)}
        prefix="$"
      />
      <Statistic
        title={
          <Row justify="end" style={{height:'37.7px'}}>
             <p style={{fontSize:"18px"}}>Tax</p>
            <Switch style={{ margin: "3px 0px 0px 10px " }}
              onChange={(e) => taxUpdate(e)}
              checked={order?.tax} />
           </Row>}
        value={order?.tax === 0 ? 'NA' :`$${(order?.totalPrice - order?.subTotal)?.toFixed(2)} (${
          order?.tax
        }%)`}
      />
      <Statistic
        title="Order Total Inc Tax "
        value={`${order?.totalPrice || 0} ${zohoAccountCheck(order) || ''}`}
        prefix="$"
      />
      <Statistic
        title="Status"
        value={order?.stage === 5 ? '100% Paid' : `${paymentStatus(order)}% Paid`}
        valueStyle={{ color: "#3f8600" }}
        prefix={<CheckCircleFilled />}
      />
      <Statistic
        title="Remaining"
        value={order?.stage === 5 ? '0%' :`${paymentRemaining(order)}`}
        valueStyle={{ color: "#cf1322" }}
        prefix="$"
      />
    </div>
  );

  const description = (
    <div>
      <h3>Order Information</h3>
      <Descriptions className={styles.headerList} size="large">
        <Descriptions.Item label="Order ID">
          <u>
            <strong>{order?.orderID}</strong>
          </u>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions className={styles.headerList} size="small"
      column={{
        xxl: 2,
        xl: 2,
        lg: 2,
        md: 2,
        sm: 1,
        xs: 1,
      }}>
        <Descriptions.Item label="Total Materials">
          {order?.totalSize || 0} SQF
        </Descriptions.Item>
        <Descriptions.Item label="Slabs">
          {order?.orderSlabs?.length}
          {order?.specialProducts.length > 0 ? (
            <Tag color={"yellow"}>
              ({order?.specialProducts.length} Special Products)
            </Tag>
          ) : (
            ``
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Sales Rep">
          {" "}
          <Link key={1} to={`/account/center/${order?.User.id}`}>
            {order?.User.name}
          </Link>
        </Descriptions.Item>

        <Descriptions.Item label="Created On">
          {order?.createdAt}{" "}
        </Descriptions.Item>
        <Descriptions.Item label="Notes">
          <Alert
            message={<i>{order?.note}</i>}
            type="warning"
            showIcon={false}
            banner
          />
        </Descriptions.Item>
      </Descriptions>
      <Divider></Divider>
      <h3>Contact Information</h3>
      {order?.client ? (
        <Descriptions className={styles.headerList} size="small"
        column={{
        xxl: 2,
        xl: 2,
        lg: 2,
        md: 2,
        sm: 1,
        xs: 1,
      }}>
          <Descriptions.Item label="Contact">
            {order?.client?.name} &nbsp;
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {order?.client?.email} / {order?.client?.email1}
          </Descriptions.Item>
          <Descriptions.Item label="Phone">
            {" "}
            {order?.client?.phone} / {order?.client?.mobile}
          </Descriptions.Item>
          <Descriptions.Item label="Address">
            {order?.client?.shipping_address_text}
          </Descriptions.Item>
          <Descriptions.Item label="Past Orders"></Descriptions.Item>
        </Descriptions>
      ) : (
        <Button onClick={() => setPreviewVisible(true)} type="primary">
          Add Contact
        </Button>
      )}
      {order?.zohoCompany?.Account_Name && <Descriptions>
      <Descriptions.Item label="Company">{order?.zohoCompany?.Account_Name || ''}</Descriptions.Item>
      </Descriptions>}
    </div>
  );

  const operationTabList = [
    {
      key: "tab1",
      tab: (
        <span>
          <ShoppingTwoTone /> Products
        </span>
      ),
    },
    {
      key: "tab2",
      tab: (
        <span>
          <StarTwoTone /> Special Products
        </span>
      ),
    },
    {
      key: "tab3",
      tab: (
        <span>
          <FilePdfTwoTone /> Quote
        </span>
      ),
      disabled: !order?.client || (order?.outOfstatePDF && order?.outofStateValidity === null),
    },

    {
      key: "tab4",
      tab: (
        <span>
          <MessageTwoTone /> Emails
        </span>
      ),
      disabled: !order?.client || (order?.outOfstatePDF && order?.outofStateValidity === null),
    },
    {
      key: "tab5",
      tab: (
        <span>
          <DollarTwoTone />
          Payments
        </span>
      ),
      disabled: order?.stage < 1,
    },

    {
      key: "tab6",
      tab: (
        <span>
          <FlagTwoTone /> Sale Order
        </span>
      ),
      disabled: order?.stage < 2,
    },
    {
      key: "tab7",
      tab: (
        <span>
          <FileTextTwoTone /> Invoice
        </span>
      ),
      disabled: order?.stage < 2,
    },
    {
      key: "tab8",
      tab: order?.outOfstatePDF && order?.outofStateValidity === null && (
        <Alert
          message="Validate Out of order form to send Quote"
          type="error"
        />
      ),
      disabled: true,
    },
  ];

  const contentList = {
    tab1: <Products data={order?.slabs} order={order} syncOrder={getOrder} />,
    // tab1: <Products data={order?.orderSlabs} order={order} syncOrder={getOrder} />,

    tab2: (
      <SpecialProducts
        data={order?.specialProducts}
        order={order}
        syncOrder={getOrder}
      />
    ),
    tab5: <Payments order={order} syncOrder={getOrder} />,
    tab3: <QuotePDF order={order} syncOrder={getOrder} />,
    tab4: <Emails order={order} syncOrder={getOrder} />,
    tab6: <SalePDF order={order} syncOrder={getOrder} />,
    tab7: <Invoices order={order} syncOrder={getOrder} />,
  };

  const onTabChange = (tabActiveKey) => {
    seTabStatus({ ...tabStatus, tabActiveKey });
  };

  const onOperationTabChange = (key) => {
    seTabStatus({ ...tabStatus, operationKey: key });
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  return (
    <PageContainer
      title={
        <>
          <h4 style={{ marginBottom: "10px" }}>
            <strong style={{ color: "blue" }}>{order?.description}</strong>
            &nbsp;&nbsp;
            <Tag color="geekblue">{order?.status}</Tag>
          </h4>
        </>
      }
      loading={loading}
      extra={action}
      className={styles.pageHeader}
      content={description}
      extraContent={extra}
      tabActiveKey={tabStatus.tabActiveKey}
      onTabChange={onTabChange}
    >
      {getUser().role !== "ACCOUNTING" &&
        <div className={styles.main}>
        <GridContent>
          <OrderProgress order={order} syncOrder={getOrder} />
          <Card
            className={"order-modules-header"}
            bordered={false}
            tabList={operationTabList}
            tabBarExtraContent={extraActions}
            onTabChange={onOperationTabChange}
            defaultActiveTabKey="tab1"
          >
            {contentList[tabStatus.operationKey]}
          </Card>
        </GridContent>
      </div>}
      <Modal
        title="Edit Order"
        centered
        maskClosable={false}
        visible={modalVisible}
        okText="Save Order"
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width="60%"
        style={{}}
      >
        <Form
          {...layout}
          name="nest-messages"
          onFinish={(values) => handleUpdate(values, order.id)}
          validateMessages={validateMessages}
          initialValues={order}
        >
          <Tabs defaultActiveKey="2">
            <TabPane tab={<span>📄 Order Detail</span>} key="1">
              <Form.Item name={"description"} label="Opportunity name">
                <Input />
              </Form.Item>
              <Form.Item name={"note"} label="Note" rules={[]}>
                <Input.TextArea rows={5} />
              </Form.Item>
            </TabPane>
            <TabPane tab={<span>🚚 Out Of State Form</span>} key="2">
              {order?.outOfstatePDF && (
                <>
                  <Divider plain>Attached</Divider>
                  <Button
                    type="link"
                    onClick={() => {
                      window.open(order?.outOfstatePDF, "_blank");
                    }}
                  >
                    <strong>
                      <SelectOutlined /> Out of state form
                    </strong>
                  </Button>
                  <Divider></Divider>
                </>
              )}

              <Form.Item label="Out of state form">
                <ImageUpload
                  callback={signatureCallback}
                  limit={1}
                  images={[]}
                  slug="resellForm"
                />
              </Form.Item>

              <Form.Item name="outofStateValidity" label="Validity">
                <Switch disabled={getUser().role === 'ADMIN' || getUser().role === "ACCOUNTING" ? false : true } />
              </Form.Item>
            </TabPane>
            <TabPane tab={<span>#PO</span>} key="3">
              <Form.Item name={["PO", "Note_Title"]} label="Add title">
                <Input />
              </Form.Item>
              <Form.Item name={["PO", "Note_Content"]} label="Add a note" rules={[]}>
                <Input.TextArea rows={5} />
              </Form.Item>
            </TabPane>
          </Tabs>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Save Order
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={
          <span>
            <PieChartFilled /> Reports
          </span>
        }
        centered
        maskClosable={false}
        visible={modalVisible1}
        okText="Okay"
        onOk={() => setModalVisible1(false)}
        onCancel={() => setModalVisible1(false)}
        width="70%"
        style={{}}
      >
        <Reports order={order} />
      </Modal>
      <Modal
        title='Closing Reason'
        centered
        okButtonProps={{ disabled: !closingReason }}
        maskClosable={false}
        visible={reasonModalVisible}
        okText="Save"
        onOk={() => {
          handleUpdate({stage:6,status:'lost'}, id)
          setReasonModalVisible(false)
        }}
        onCancel={() => setReasonModalVisible(false)}
        width="40%"
        style={{}}
      >
        <ProFormRadio.Group
          name="closing_reason"
          layout="vertical"
          onChange={(e)=>{setClosingReason(e?.target?.value)}}
  options={[
    {
      label: 'ETA',
      value: 'ETA',
    },
    {
      label: 'Inventory availability',
      value: 'Inventory availability',
    },
    {
      label: 'Loss of interest',
      value: 'Loss of interest',
    },
    {
      label: 'No samples available',
      value: 'No samples available',
    },
    {
      label: 'Others',
      value: 'Others',
    },
    {
      label: 'Payment conditions',
      value: 'Payment conditions',
    },
    {
      label: 'Price',
      value: 'Price',
    },
    {
      label: 'Product out of catalogue',
      value: 'Product out of catalogue',
    },
    {
      label: 'Quality',
      value: 'Quality',
    },
  ]}
/>
      </Modal>
      <Modal
        visible={previewVisible}
        title="Add Contact"
        okText="Add"
        okButtonProps={{ disabled: order?.User?.zohoID && !zohoAccount }}
        // zoho updated
        // okButtonProps={{ disabled:  !zohoAccount  }}
        onOk={() => handleUpdate({clientId:client.id,tax: checkingTAX(getUser(), client)}, order.id)}
        onCancel={() => {
          setPreviewVisible(false);
        }}
        width="60vw"
      >
        <LinkClient setClient={setClient} client={client} zohoAccount={zohoAccount} setZohoAccount={setZohoAccount} />
      </Modal>
        <Owner syncOrder={getOrder} order={order} modalVisible2={modalVisible2} setModalVisible2={setModalVisible2} />
    </PageContainer>
  );
};

export default Order;
