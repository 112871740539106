import { DeleteOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { PageContainer } from "@ant-design/pro-layout";
import ProTable from "@ant-design/pro-table";
import {Statistic, Button, Dropdown, Menu, message, Radio } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OrderStatus from "../../components/Orders/OrderStatus";
import { debounce } from "../../helpers";
import { getUser } from "../../helpers";
import marbleApi from "../../services/http/marbleApi";
import "./index.less";

function Orders(props) {
  const [filter, setFilter] = useState({});
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [orders, setOrders] = useState([]);
  const [show, setShow] = useState(false);
  const actionRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    debounce(() => actionRef.current.reload())();
  }, [filter]);

  const updateFilter = (key, value) => {
    if ((Array.isArray(value) && value.length === 0) || value === "") {
      setFilter((prevData) => {
        const newData = { ...prevData };
        delete newData[key];
        return newData;
      });
      return;
    }
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "orderID",
      sorter: true,
     search: show,
      render: (dom, entity) => {
        return (
          <h4>
            {" "}
            <Link key={1} to={`/orders/${entity.id}`}>
              {dom}
            </Link>
          </h4>
        );
      },
    },
    {
      title: "Opportunity name",
      dataIndex: "description",
      sorter: true,
    },
    {
      title: "Sales Rep.",
      dataIndex: "User",
      sorter: true,
      render: (dom, entity) => {
        return (
          getUser().role === "ACCOUNTING" ? <p>{dom.name}</p>
            :
          <Link key={1} to={`/account/center/${dom.id}`}>
            {dom.name}
          </Link>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (dom, entity) => {
        let t = null;
        return <OrderStatus orderStatus={entity.status} />;
      },
    },
    {
      title: "Materials",
      dataIndex: "totalSize",
      search: false,
      sorter: true,
      hideInForm: true,
      renderText: (val) => `${val || 0} Sqf.`,
    },
    {
      title: "Amount",
      dataIndex: "totalPrice",
      search: false,
      sorter: true,
      hideInForm: true,
      render: (dom, entity) => {
        return (
        <Statistic
        value={entity?.totalPrice || 0}
        prefix="$"
      />
        );
      },
     
      renderText: (val) => `$ ${val}`,
    },
    {
      title: "Payment",
      dataIndex: "percent",
      search: false,
      sorter: true,
      hideInForm: true,
      renderText: (val) => `NA`,
    },
    {
      title: "Contact",
      dataIndex: "client",
      render: (dom, entity) => {
        return (
          <h4>
            {" "}
            <Link key={1} to="#">
              {entity?.client?.name}
            </Link>
          </h4>
        );
      },
    },

    {
      title: "Created On",
      sorter: true,
      search: false,
      dataIndex: "updatedAt",
      valueType: "dateTime",
    },
  ];

  const listOrder = async (
    params = {
      current: page,
      pageSize,
    },
    options
  ) => {
    const user = getUser();
    let location
    let nested
    if (user.role !== "ADMIN") {
        location = user.location;
    }
    if (params.User) {
      nested = { order: `User_${params.User}` }
      delete params.User
    }
    if (params.client) {
      nested = { order: `client_${params.client}` }
      delete params.client
    }
    try {
      const response = await marbleApi.http.post(marbleApi.urls.order.list, {
        params: { ...params, ...filter },
        options,
        location,
        nested
      });
      if (response) {
        setPageSize(response.pageSize);
        setPage(response.current);
        setTotal(response.total);
        return response;
      }
    } catch (error) {
      message.error(error?.message);
      return {};
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="1"></Menu.Item>
    </Menu>
  );

  return (
    <PageContainer title="StonePro">
      <ProTable
        actionRef={actionRef}
        rowKey="key"
        search={{
          labelWidth:155,
          onCollapse: ()=>{setShow((prevValue) => !prevValue)}
        }}
        options={{
          fullScreen: true,
          reload: true,
          density: false,
          setting: false,
        }}
        toolBarRender={() => [
          <Radio.Group
            className='order_radio'
            defaultValue="a"
            buttonStyle="solid"
            style={{ float: "left" }}
            key={1}
            onChange={(e) => updateFilter("status", e.target.value)}
          >
            <Radio.Button value="">All</Radio.Button>
            <Radio.Button value="quote">Quotes</Radio.Button>
            <Radio.Button value="sale">Sale orders</Radio.Button>
            <Radio.Button value="complete">Complete</Radio.Button>
            <Radio.Button value="draft">Draft</Radio.Button>
            <Radio.Button value="lost">Close Lost</Radio.Button>
          </Radio.Group>,
          <Button
            key={2}
            type="primary"
            disabled={getUser().role === "ACCOUNTING"}
            onClick={() => {
              navigate("/orders/create-new-order");
            }}
          >
            <PlusOutlined /> Create New Order
          </Button>,
          // <Dropdown key="menu" overlay={menu}>
          //   <Button type="link">
          //     <MoreOutlined />
          //   </Button>
          // </Dropdown>,
        ]}
        request={listOrder}
        columns={columns}
      />
    </PageContainer>
  );
}

export default Orders;
