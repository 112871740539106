import {
  GoldOutlined,
  MoreOutlined,
  SearchOutlined,
  SelectOutlined,
  SyncOutlined,
  TagsFilled,
} from "@ant-design/icons";
import { PageContainer } from "@ant-design/pro-layout";
import {
  Badge,
  Button,
  Card,
  Cascader,
  Col,
  Collapse,
  Drawer,
  Dropdown,
  Input,
  InputNumber,
  Menu,
  message,
  Pagination,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import marbleApi from "../../services/http/marbleApi";
import ProductView from "./components/ProductView";
import { debounce } from "../../helpers";
import { useParams } from "react-router-dom";

const stc = require("string-to-color");

const { Option } = Select;
const { Meta } = Card;
const { Panel } = Collapse;

function Products(props) {
  const [visible, setVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(null);
  const [optionsFilter, setOptions] = useState({
    primaryImage: "ascend",
  });
  const [nested, setNested] = useState(null);
  const [productOptions, setProductOptions] = useState({});
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [productModalTitle, setProductModalTitle] = useState(<></>);

  const { callback } = props;

  const params = useParams();
  const { id } = params;

  useEffect(() => {
    getOptions();
    // listProducts();
  }, []);

  useEffect(() => {
    if (filter || nested) {
      debounce(() => listProducts(), 800)();
    }
    else {
      listProducts();
    }
  }, [filter, optionsFilter, nested, page, pageSize]);

  useEffect(() => {
    if (id?.includes('product') && products.length > 0) {
      const parts = id.split('_');
      const numericID = parts[1];
      const pro = products.find(i => i.sku === numericID);
      if (pro) {
        setSelectedProductId(pro.id)
      }
    }
  }, [products]);

  // useEffect(() => {
  //   debounce(() => listProducts(), 800)();
  // }, [optionsFilter]);

  // useEffect(() => {
  //   debounce(() => listProducts(), 800)();
  // }, [nested]);

  // useEffect(() => {
  //   listProducts();
  // }, [page, pageSize]);

  useEffect(() => {
    if (selectedProductId) {
      setVisible(true);
    }
  }, [selectedProductId]);

  const updateFilter = (key, value) => {
    if ((Array.isArray(value) && value.length === 0) || value === "") {
      setFilter((prevData) => {
        const newData = { ...prevData };
        delete newData[key];
        return newData;
      });
      return;
    }
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const updateNested = (key, value) => {
    setNested((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  //get correct inventory_ information
  const getProductInventory = (product_) => {
    const inventory_ = {
      existing: 0,
      available: 0,
      commited: 0,
      blocks: 0,
      slabs: 0,
      slabs_commited: 0,
    };

    product_?.blocks?.forEach((block) => {
      inventory_.blocks += 1;
      block.slabs.forEach((slab) => {
        inventory_.slabs += 1;
        inventory_.existing += slab.existing;
        inventory_.available += slab.available;
        inventory_.commited += slab.commited;
        if (slab.commited > 0) {
          inventory_.slabs_commited += 1;
        }
      });
    });

    return inventory_;
  };

  const getOptions = async () => {
    setLoading(true);
    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.products.options,
        {}
      );
      if (response) {
        setProductOptions(response.data);
      }
    } catch (error) {
      message.error(error?.message);
      return {};
    }
    setLoading(false);
  };

  const listProducts = async (
    params = {
      current: page,
      pageSize,
      status_enum: "ACTIVE",
    },
    options = optionsFilter
  ) => {
    setLoading(true);
    // eslint-disable-next-line no-param-reassign
    try {
      const response = await marbleApi.http.post(marbleApi.urls.products.list, {
        params: { ...params, ...filter },
        options,
        nested,
      });
      if (response) {
        setProducts(response.data);
        setPageSize(response.pageSize);
        setPage(response.current);
        setTotal(response.total);
        setLoading(false);
      }
    } catch (error) {
      message.error(error?.message);
      setLoading(false);
      return {};
    }

  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <SyncOutlined /> Sync Products From SAP
      </Menu.Item>
    </Menu>
  );

  function tagRender(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={stc(value)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
    );
  }

  const familySubfamily = () => {
    const options = [];
    for (let [key, value] of Object.entries(
      productOptions?.familySubFamily || {}
    )) {
      const option = {};
      option.value = key;
      option.label = key;
      option.children = value.map((item) => ({ value: item, label: item }));
      options.push(option);
    }
    return options;
  };

  return (
    <PageContainer
      title="Products"
      extra={[
        <>
          <Row gutter={4} className="filter-top-row">
            <Col>
              <Input
                placeholder="Name"
                allowClear
                onChange={(e) => updateFilter("tagSearch", e.target.value)}
              />
            </Col>
            {/* <Col>
              <Input
                placeholder="SKU"
                allowClear
                onChange={(e) => updateFilter("sku", e.target.value)}
              />
            </Col> */}
            <Col>
              {" "}
              <Input
                placeholder="Block"
                allowClear
                onChange={(e) => updateNested("blockId", e.target.value)}
              />
            </Col>
            <Col>
              {" "}
              <Input
                placeholder="Slab"
                allowClear
                onChange={(e) => updateNested("slabId", e.target.value)}
              />
            </Col>
            <Col>
              {" "}
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() =>listProducts()}
              />
            </Col>
            <Col>
              <InputNumber
                placeholder="Size"
                onChange={(value) => updateNested("size", value)}
                // addonAfter={
                //   <Select
                //     placeholder="Select"
                //     onChange={(value) => updateNested("order", value)}
                //     style={{
                //       width: 120,
                //     }}
                //   >
                //     <Option value="gt">Greater than</Option>
                //     <Option value="equals">Equals to</Option>
                //     <Option value="lt">Less than</Option>
                //   </Select>
                // }
              />
            </Col>
            <Col>
              {" "}
              <Select
                defaultValue="primaryImage-ascend"
                style={{ width: 140 }}
                onChange={(value) => {
                  const data = value.split("-");
                  setOptions({ [data[0]]: data[1] });
                }}
                placeholder="Sort By"
              >
                <Option value="sapUpdatedAt-descend">New to old</Option>
                <Option value="sapUpdatedAt-ascend">Old to new</Option>
                <Option value="primaryImage-ascend">By Images</Option>
                <Option value="sapUpdatedAt-descend">
                  Shopify : New to Old
                </Option>
                <Option value="sapUpdatedAt-ascend">
                  {" "}
                  Shopify : Old to New
                </Option>
              </Select>
            </Col>
            <Col>
              {" "}
              <Radio.Group
                defaultValue="b"
                buttonStyle="solid"
                onChange={(e) => updateFilter("status_enum", e.target.value)}
              >
                <Radio.Button value="">All</Radio.Button>
                <Radio.Button value="ACTIVE">Active</Radio.Button>
                <Radio.Button value="INACTIVE">Archive</Radio.Button>
              </Radio.Group>
            </Col>
            <Col>
              <Select
                placeholder="Locaton"
                onChange={(value) => updateNested("warehouse", value)}
              >
                <Option value="1">Van Nuys</Option>
                <Option value="2">Anaheim</Option>
              </Select>
            </Col>
            <Col>
              {" "}
              <Dropdown key="menu" overlay={menu}>
                <Button type="link">
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </>,
      ]}
    >
      <Card key="filter-2-card">
        <Row gutter={4} className="filter-top-row">
          <Col>
            <Select
              mode="multiple"
              allowClear
              style={{ width: 247 }}
              placeholder="Material"
              defaultValue={[]}
              onChange={(value) => updateFilter("material", value)}
              options={
                productOptions?.materials?.map((color) => ({
                  value: color,
                })) || []
              }
            />
          </Col>
          <Col>
            <Input
              placeholder="Type"
              allowClear
              onChange={(e) => updateFilter("type", e.target.value)}
            />
          </Col>

          <Col>
            <Select
              mode="multiple"
              showArrow
              tagRender={tagRender}
              placeholder="Color"
              defaultValue={[]}
              style={{ width: 247 }}
              onChange={(value) => updateFilter("color", value)}
              options={
                productOptions?.colors?.map((color) => ({
                  value: color,
                })) || []
              }
            />
          </Col>
          {/* <Col span={5}>
            <Cascader
              options={familySubfamily() || []}
              expandTrigger="hover"
              onChange={(value) => {
                let family = "";
                let subfamily = "";
                if (value && value.length > 0) {
                  [family, subfamily] = value;
                }

                updateFilter("family", family);
                updateFilter("subfamily", subfamily);
              }}
              placeholder="Family/Sub-family"
              style={{ width: "100%" }}
            />
          </Col> */}
          <Col>
            <Button
              type="primary"
              style={{ width: 30 }}
              icon={<SearchOutlined />}
              block
              onClick={listProducts}
            />
          </Col>
          <Col>
              {" "}
              <Radio.Group
                defaultValue="b"
                buttonStyle="solid"
                onChange={(e) => updateNested("availability", e.target.value)}
              >
                {/* <Radio.Button value="onhold">On Hold</Radio.Button> */}
                <Radio.Button value="available">Available</Radio.Button>
                <Radio.Button value="committed">Committed</Radio.Button>
              </Radio.Group>
            </Col>
        </Row>
      </Card>

      <Spin spinning={loading}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ padding: 20 }}>
          {products.map((product, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 12, offset: 0 }}
              md={{ span: 8, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              style={{ padding: "30px 20px" }}
              key={product.id}
            >
              <Card
                key={product.sku}
                loading={loading}
                onClick={async () => {
                  await setSelectedProductId(null);
                  setSelectedProductId(product.id);
                }}
                hoverable
                cover={
                  product.primaryImage ? (
                    <img
                      alt="example"
                      style={{
                        objectFit: "cover",
                        height: "300px",
                      }}
                      src={product.primaryImage}
                    />
                  ) : (
                    <div
                      style={{
                        height: "300px",
                        width: "auto",
                        backdropFilter: "saturate(180%) blur(20px)",
                        borderRadius: "15px 15px 0px 0px",
                        background: "black",
                        opacity: 0.12,
                      }}
                    />
                  )
                }
                actions={[
                  <span key={1}>
                    <GoldOutlined />
                    {/* &nbsp;{product.slabs} */}
                    &nbsp;{getProductInventory(product).slabs}
                  </span>,
                  <span key={2}>
                    <TagsFilled />
                    {/* &nbsp;{product.existing} SQF  */}
                    &nbsp;{getProductInventory(product).available.toFixed(2)} SQF
                  </span>,
                  <Tooltip title="open product page" key={3}>
                    <SelectOutlined key="open" />
                  </Tooltip>,
                ]}
              >
                <span className="product-grid-title">
                  <Badge className="bigbadgedot" color={stc(product.color)} />
                  {product.name}
                </span>
              </Card>
            </Col>
          ))}{" "}
        </Row>
      </Spin>

      <Pagination
        defaultCurrent={page}
        total={total}
        pageSize={pageSize}
        onChange={(page, pageSize) => {
          setPage(page || 1);
          setPageSize(pageSize);
        }}
        responsive
        style={{
          textAlign: "center",
        }}
      />
      <Drawer
        title={productModalTitle}
        placement="right"
        onClose={() => {
          setVisible(false);
        }}
        visible={visible}
        width="90vw"
      >
        <ProductView
          id={selectedProductId}
          setTitle={setProductModalTitle}
          callback={callback}
          nested={nested}
        />
      </Drawer>
    </PageContainer>
  );
}

export default Products;
