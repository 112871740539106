// export const BASE_URL = "http://localhost:8001/api/v1";
export const BASE_URL = "https://api.stoneproapp.com/api/v1";

export const warehouses_real = {
  "SFC-00": "Inventario Inicial",
  "SFC-01": "San Fernando",
  "SFC-02": "Antolini Partner Inventory",
  "SFC-03": "MM Partner Inventory",
};

export const warehouses = {
  "SFC-00": "Van Nuys",
  "SFC-01": "Van Nuys",
  "SFC-02": "Van Nuys",
  "SFC-03": "Anaheim",
  "LAAN": "Anaheim",
};
